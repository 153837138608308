import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { builder, Builder, BuilderComponent } from '@builder.io/react';
import loadable from '@loadable/component';
import '../../static/css/funnel.css';
import { FunnelProvider } from '../context/FunnelContext';
import axios from 'axios';
import Microdata from '../components/Microdata/Microdata';
import { useFunnelContext } from '../hooks/useFunnelContext';
import { useRedirect } from '../hooks/useRedirect';
import { useProductSelection } from '../hooks/useProductSelection';
import StagingIndicator from '../components/StagingIndicator/StagingIndicator';
import { useViewTracking } from '../hooks/useViewTracking';

const Helmet = loadable(() => import('react-helmet'));

builder.init(process.env.GATSBY_BUILDER_API_KEY);

const defaultTitle = 'PetLab Co.';
const defaultDescription = 'Supplies, Treats, Snacks & Chews - Join The Pack';

function FunnelTemplate(props) {
  const {
    data: { allBuilderModels },
    '*': tokenQuery
  } = props;

  const {
    oneFunnel: {
      data: {
        design = 'design1',
        currentCategory = 'subscriptions',
        settings,
        redirectToEcomm,
        redirectToEcommUrl,
        geoRedirection,
        microdata,
        variantId: pageVariantId = 'DEFAULT'
      },
      content: funnelContent
    }
  } = allBuilderModels;

  const funnelSettings = settings || {
    initToken: 'none',
    defaultTubIndex: 0
  };

  const {
    initToken,
    defaultTubIndex,
    endpointApiUrl,
    tokenExpiryUrl
  } = funnelSettings;

  const contextFunnel = useFunnelContext(
    props,
    defaultTubIndex,
    currentCategory
  );

  const {
    tokenResultsLoaded,
    setTokenResultsLoaded,
    subscription,
    onetime
  } = contextFunnel;

  const context = {
    ...contextFunnel,
    pageDesign: design,
    microdata: microdata,
    pageVariantId
  };

  const { extraObjects, setExtraObjects } = context;

  useRedirect(geoRedirection, redirectToEcomm, redirectToEcommUrl);
  useViewTracking(currentCategory, subscription, onetime, microdata);
  useProductSelection(contextFunnel);

  useEffect(() => {
    if (!tokenResultsLoaded && tokenQuery && tokenQuery !== ``) {
      setExtraObjects(
        Object.assign(extraObjects, {
          tokenQuery: tokenQuery
        })
      );

      (async function fetchData() {
        switch (initToken) {
          case 'payment-update':
            await axios
              .get(endpointApiUrl, {
                headers: { 'access-token': tokenQuery }
              })
              .then(function(response) {
                const results = response.data;
                setExtraObjects(
                  Object.assign(extraObjects, {
                    user: {
                      ...results
                    }
                  })
                );
              })
              .catch(function() {
                if (!Builder.isEditing) {
                  window.location.href = tokenExpiryUrl;
                }
              });

            return () => {
              setTokenResultsLoaded(true);
            };
          case 'subscription-upgrade':
            await axios
              .get(endpointApiUrl, {
                token: tokenQuery
              })
              .then(function(response) {
                const results = response.data;

                setExtraObjects(
                  Object.assign(extraObjects, {
                    active_subscription_token: tokenQuery,
                    active_subscription_special_offer: 0,
                    active_subscription_discount_type: 'DISCOUNT_25',
                    active_subscription_data: results
                  })
                );
              })
              .catch(function() {
                if (!Builder.isEditing) {
                  window.location.href = tokenExpiryUrl;
                }
              });

            return () => {
              setTokenResultsLoaded(true);
            };
          case 'none':
          default:
            return () => {
              setTokenResultsLoaded(true);
            };
        }
      })();
    }
  }, [
    tokenQuery,
    endpointApiUrl,
    initToken,
    tokenExpiryUrl,
    currentCategory,
    extraObjects,
    setExtraObjects,
    tokenResultsLoaded,
    setTokenResultsLoaded
  ]);

  return (
    <>
      {!redirectToEcomm && (
        <FunnelProvider value={context}>
          <Helmet
            bodyAttributes={{
              class: `petlab-funnel-1 ${design}`
            }}
            htmlAttributes={{
              lang: 'en'
            }}
          >
            <title>
              {(funnelContent && funnelContent?.data?.siteMeta?.title) ||
                defaultTitle}
            </title>
            <meta
              name="description"
              content={
                (funnelContent && funnelContent?.data?.siteMeta?.description) ||
                defaultDescription
              }
            />
            <meta name="funnel-id" content={funnelContent?.id} />
            <meta
              name="product-selector-id"
              content={funnelContent?.data?.products?.id}
            />
            <meta
              name="magic-key"
              content="t54cdW3cbU45TWPRtYGUR4fyBnWHe49jWeuqdRStfAvELctRyfyXLmDteF9Cf9jhPsyzDfkcCK5DNU6Pr87HU8tgNKBDDmeHDFQn"
            />
          </Helmet>
          <Microdata path={props.pageContext.pagePath} />
          {/** name of the model is landing page, change it if you decided to build*/}
          <BuilderComponent
            model="funnel"
            content={funnelContent}
            data={{
              ...context
            }}
          ></BuilderComponent>
        </FunnelProvider>
      )}
      {process.env.GATSBY_FUNNEL_STAGING && <StagingIndicator />}
    </>
  );
}

export const query = graphql`
  query($funnelId: String) {
    allBuilderModels {
      oneFunnel(
        query: { id: $funnelId }
        options: { cachebust: true, includeRefs: true }
      ) {
        content
        data {
          design
          currentCategory
          settings
          redirectToEcomm
          redirectToEcommUrl
          geoRedirection
          microdata
          variantId
        }
      }
    }

    funnelPageData(funnelId: { eq: $funnelId }) {
      ...FunnelDataFragment
    }
  }
`;

export default FunnelTemplate;
